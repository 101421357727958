<template>
    <v-row no-gutters>
        <v-col cols="12" sm="12" md="4">
            <label class="f-label">{{$t(label)}}</label>
        </v-col>
        <v-col cols="12" sm="12" md="8">
            <v-switch 
                @change="handle"
                v-model="content" 
                class="cust-switch" 
                inset 
                hide-details
            ></v-switch>
        </v-col>
    </v-row> 
</template>
<script>
export default {
    name: "FieldEditBool",
    props: {
        label: {
            type: String,
            default: null
        },
        value: {
            type: Boolean,
            default: false
        }
    }, 
    data() {
        return {
            content: this.value
        }
    },
    methods: {
        handle(e) {
            this.$emit('input', e);
        }
    }
};
</script>

